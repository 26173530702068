import axios from "axios";

import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const employeeSafetyCertificationsApi = {
  getAll: async (params = {}) => {
    const queryParams = new URLSearchParams(params);
    const url = `/api/employee-safety-certifications?${queryParams.toString()}`;

    return axios(url, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "GET",
    })
      .then((response) => response.data)
      .catch((error) => apiHelper.handleError(error));
  },
};
