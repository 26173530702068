import React, { useEffect, useState } from "react";

import { Table } from "reactstrap";
import { DISABLED_PERDIEM_CALCULATE } from "../../../helpers/perDiemHelper";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { useAuth } from "../../../providers/authProvider";
import { commonApi } from "../../../services/commonServices";

const PERDIEM_AHP_ENABLED = "PERDIEM_AHP_ENABLED";

const CrewWorkDayTruckDrivers = ({ crewWorkDay, workOrderPartType }) => {
  const [authContext] = useAuth();
  const [perDiemAHPSetting, setPerDiemAHPSetting] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const perDiemAHPSetting = await commonApi.getSettings({
        name: PERDIEM_AHP_ENABLED,
      });

      setPerDiemAHPSetting(perDiemAHPSetting?.data?.[0]);
    };

    fetchData();
  }, []);

  const perDiemCalculateEnabled = () => {
    const hasPerDiemAHPType =
      DISABLED_PERDIEM_CALCULATE.includes(workOrderPartType);

    return !hasPerDiemAHPType || perDiemAHPSetting?.status;
  };

  const truckDrivers = crewWorkDay.employeeTruckDrivers;

  const truckDriverRole = authContext.currentUser.roleOptions.find(
    (role) => role.name === "Truck Driver"
  );

  const flatDailyRate = perDiemCalculateEnabled()
    ? utilsHelper.formatCurrency(
        truckDriverRole.options.payroll.pickupTruckDriver.flatDailyRate
      )
    : "N/A";

  const truckDriverFlatRate = truckDriverRole ? flatDailyRate : "N/A";

  const allCrew = Object.values(
    crewWorkDay.employees
      .filter((e) =>
        utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false)
      )
      .reduce((p, c) => {
        if (!p[c.employee.id] || c.activeOnDate) {
          p[c.employee.id] = c;
        }
        return p;
      }, {})
  );

  return (
    <div key={crewWorkDay.id} className="d-flex">
      <Table className="col-12 px-0 mb-0 border">
        <thead>
          <tr className="bg-lighter">
            <th>
              <div className="d-flex flex-column">
                <span>CREW</span>
              </div>
            </th>
            <th className="text-right align-middle">FLAT RATE</th>
          </tr>
        </thead>
        <tbody>
          {allCrew.length ? (
            !truckDrivers.length ? (
              <tr>
                <td colSpan={4} className="p-2 small text-muted text-center">
                  No Truck Drivers
                </td>
              </tr>
            ) : (
              truckDrivers
                .sort((a, b) =>
                  a.employee.firstName < b.employee.firstName ? -1 : 1
                )
                .map((truckDriver) => {
                  return (
                    <tr>
                      <td>
                        {utilsHelper.getEmployeeLabelWithPreferredName(
                          truckDriver.employee
                        )}
                      </td>
                      <td className="text-right">{truckDriverFlatRate}</td>
                    </tr>
                  );
                })
            )
          ) : (
            <td colSpan={4} className="p-2 small text-muted text-center">
              Empty Crew
            </td>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CrewWorkDayTruckDrivers;
