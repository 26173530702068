import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { reportsApi } from "../../../../services/reportsServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../../helpers/utilsHelper";
import WeeklyPayrollDetailReport from "../WeeklyPayrollDetailReport";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import InformationModal from "../../../../components/InformationModal";
import { useAuth } from "../../../../providers/authProvider";
import WeekSelector from "../../../../components/admin/WeekSelector";
import { reportsHelper } from "../../../../helpers/reportsHelper";
import Loader from "../../../../components/Loader";
import PeriodTypeSelector from "../../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../../components/admin/CustomPeriodSelector";

const dateFormat = "MM/DD";
const WEEK_DAYS = 7;

const getDateLabel = (date, increment) => {
  const incrementDate = moment(date).add(increment, "day");
  return `${incrementDate.format("dd")} ${incrementDate.format(dateFormat)}`;
};

const SMALL_COL_SIZE = 70;
const BIG_COL_SIZE = 150;

const COLS_LENGTH = 17;

const columnSizes = Array.from(Array(COLS_LENGTH).keys()).map((col) => ({
  idx: col,
  width:
    (col === 0 || col > 2) && col !== COLS_LENGTH - 1
      ? SMALL_COL_SIZE
      : BIG_COL_SIZE,
}));

const report = (date) => ({
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [
      {
        "Comp.": {
          type: "string",
        },
        Name: {
          type: "string",
        },
        Operation: {
          type: "string",
        },
        ...[0, 1, 2, 3, 4, 5, 6].reduce((p, c) => {
          p[getDateLabel(date, c)] = {
            type: "number",
          };
          return p;
        }, {}),
        "Reg.": {
          type: "number",
        },
        "Guar.": {
          type: "number",
        },
        "Ovt.": {
          type: "number",
        },
        "Doub.": {
          type: "number",
        },
        "Hol.": {
          type: "number",
        },
        "F.Rate": {
          type: "number",
        },
        Total: {
          type: "number",
        },
        "Total T&L Payment": {
          type: "number",
        },
        Creator: {
          type: "string",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Comp.",
      },
      {
        uniqueName: "Name",
      },
      {
        uniqueName: "Operation",
      },
      ...[0, 1, 2, 3, 4, 5, 6].map((increment) => ({
        uniqueName: getDateLabel(date, increment),
      })),
      {
        uniqueName: "Reg.",
      },
      {
        uniqueName: "Guar.",
      },
      {
        uniqueName: "Ovt.",
      },
      {
        uniqueName: "Doub.",
      },
      {
        uniqueName: "Hol.",
      },
      {
        uniqueName: "F.Rate",
      },
      {
        uniqueName: "Total",
      },
      {
        uniqueName: "Total T&L Payment",
      },
      {
        uniqueName: "Creator",
      },
    ],
    drillThrough: ["createdBy", "customerName", "type", "workOrder", "hours"],
    expands: {
      expandAll: true,
    },
  },
  tableSizes: {
    columns: columnSizes,
    rows: [
      {
        idx: 0,
        height: 50,
      },
    ],
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Weekly Payroll",
    },
  },
});

const MAX_HOURS_RED = 16;
const HOURS_RED = "#dc35452e";
const REPORT_NAME = "Weekly Payroll_";
const TYPE_WEEKLY = "TYPE_WEEKLY";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WeeklyPayroll = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);

  const [loading, setLoading] = useState(false);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [data, setData] = useState([]);
  const [detailModal, setDetailModal] = useState({});

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );

  const sendNotification = async () => {
    try {
      if (!data.length) {
        return setInformationModal({
          isOpen: true,
          title: "Send Notification",
          body: "There are no changes in the payroll this week",
        });
      }
      setLoading(true);
      const dataNotification = {
        jobSourceId: authContext.currentUser.jobSourceId,
        date: moment()
          .subtract(1, "week")
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
      };
      const response = await reportsApi.existsNotification(dataNotification);
      if (!response.length) {
        setLoading(false);
        setConfirmationModal({
          isOpen: true,
          onSubmit: async () => {
            setLoading(true);
            await reportsApi.sendNotification(dataNotification);
            setLoading(false);
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Send Notification",
              body: "Notification sent successfully",
            });
          },
          onClose: () => {
            setConfirmationModal(initConfirmationModal);
          },
          title: "Send Notification",
          body: `<span class="text-center">Are you sure you are ready to send a notification?</span>`,
          confirmColor: "danger",
        });
      } else {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Send Notification",
          body: "This notification has already been sent",
        });
      }
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Send Notification",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const customizeCell = useCallback(
    (cell, data) => {
      const isTypeWeekly = periodType.value === TYPE_WEEKLY;
      const currentDate = isTypeWeekly ? monday : customStartDate;

      const datesWeek = [0, 1, 2, 3, 4, 5, 6].reduce((p, c) => {
        return p.concat(getDateLabel(currentDate, c));
      }, []);
      if (datesWeek && !isNaN(data.value)) {
        for (let i = 0; i < datesWeek.length; i++) {
          if (
            data.hierarchy.caption.toString() === datesWeek[i].toString() &&
            parseInt(data.label) >= MAX_HOURS_RED
          ) {
            cell.style["background-color"] = HOURS_RED;
          }
        }
      }
    },
    [monday, customStartDate, periodType.value]
  );

  const getWeekDates = useCallback(() => {
    const dates = [];
    const isTypeWeekly = periodType.value === TYPE_WEEKLY;
    let currentDate = isTypeWeekly ? moment(monday) : moment(customStartDate);
    while (dates.length < WEEK_DAYS) {
      dates.push(currentDate);
      currentDate = moment(currentDate).add(1, "day");
    }
    return dates;
  }, [monday, periodType.value, customStartDate]);

  const getWeekDayValues = useCallback(
    (d) => {
      const weekDates = getWeekDates();
      const weekDayValues = {};
      weekDates.forEach((w) => {
        const key = `${w.format("dd")} ${w.format(dateFormat)}`;
        const dateData = d.payRoll.find(
          (e) => e.date === moment(w).format("YYYY-MM-DD")
        );
        if (dateData?.sumHours) {
          const sumHours =
            dateData.sumHours.TT +
            dateData.sumHours.REG +
            dateData.sumHours.OT +
            dateData.sumHours.DT +
            dateData.sumHours.ST +
            dateData.sumHours.SBT +
            dateData.sumHours.HT;
          weekDayValues[key] = dateData.sumHours.FLATRATE.RESTDAY
            ? "RD"
            : sumHours.toFixed(2);
        } else {
          weekDayValues[key] = null;
        }
      });
      return weekDayValues;
    },
    [getWeekDates]
  );

  const getResultDataFormatted = useCallback(
    (data) => {
      return data.map((d) => {
        const reg = parseFloat(d.weeklyPayroll.REG.toFixed(2));
        const ot = parseFloat(d.weeklyPayroll.OT.toFixed(2));
        const dt = parseFloat(d.weeklyPayroll.DT.toFixed(2));
        const ht = parseFloat(d.weeklyPayroll.HT.toFixed(2));
        const st = parseFloat(d.weeklyPayroll?.ST || 0);
        const sbt = parseFloat(d.weeklyPayroll?.SBT || 0);
        const operation = d["role.operationName"] || d["role.name"];
        const companyId = d.sourceKey;
        const travel = parseFloat(d.weeklyPayroll?.TT || 0);
        const guaranteed = parseFloat(d.weeklyPayroll?.GUARANTEED || 0);
        const flatRateValue =
          d.weeklyPayroll?.FLATRATE.RESTDAY +
          d.weeklyPayroll?.FLATRATE.PICKUPDRIVER;
        const flatRate = parseFloat(flatRateValue || 0);
        const total = (
          reg +
          ot +
          dt +
          ht +
          st +
          sbt +
          travel +
          guaranteed
        ).toFixed(2);

        const arrayCreatedBy = new Set();

        for (let i = 0; i < d.payRoll.length; i++) {
          for (let j = 0; j < d.payRoll[i].hours.length; j++) {
            arrayCreatedBy.add(d.payRoll[i].hours[j].createdBy);
          }
        }

        const rowCreatedBy = Array.from(arrayCreatedBy).join(" - ");

        return {
          "Comp.": companyId,
          Name: utilsHelper.getEmployeeReverseLabelWithPreferredName(d),
          Operation: operation,
          ...getWeekDayValues(d),
          "Reg.": reg.toFixed(2),
          "Guar.": guaranteed.toFixed(2),
          "Ovt.": ot.toFixed(2),
          "Doub.": dt.toFixed(2),
          "Hol.": ht.toFixed(2),
          "F.Rate": flatRate.toFixed(2),
          Total: total,
          "Total T&L Payment": (d["Total T&L Payment"] || 0).toFixed(2),
          Creator: rowCreatedBy,
          raw: d,
        };
      });
    },
    [getWeekDayValues]
  );

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    const isTypeWeekly = periodType.value === TYPE_WEEKLY;
    if (isTypeWeekly) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .weeklyPayroll(condition)
      .then((result) => {
        setLoading(false);
        if (pivot) {
          const resultDataFormatted = getResultDataFormatted(result);
          const currentDate = isTypeWeekly ? monday : customStartDate;
          pivot.current?.flexmonster?.updateData({
            data: [
              {
                "Comp.": {
                  type: "string",
                },
                Name: {
                  type: "string",
                },
                Operation: {
                  type: "string",
                },
                ...[0, 1, 2, 3, 4, 5, 6].reduce((p, c) => {
                  p[getDateLabel(currentDate, c)] = {
                    type: "number",
                  };
                  return p;
                }, {}),
                "Reg.": {
                  type: "number",
                },
                "Guar.": {
                  type: "number",
                },
                "Ovt.": {
                  type: "number",
                },
                "Doub.": {
                  type: "number",
                },
                "Hol.": {
                  type: "number",
                },
                "F.Rate": {
                  type: "number",
                },
                Total: {
                  type: "number",
                },
                "Total T&L Payment": {
                  type: "number",
                },
                Creator: {
                  type: "string",
                },
              },
              ...resultDataFormatted,
            ],
          });
          pivot.current?.flexmonster?.customizeCell(customizeCell);
          setData(
            resultDataFormatted.map((entry) => ({
              ...entry.raw,
              ...entry,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [
    monday,
    periodType.value,
    pivot,
    customEndDate,
    customStartDate,
    customizeCell,
    getResultDataFormatted,
    authContext.currentUser.jobSourceId,
  ]);

  const dateCellClick = (cell, current) => {
    const date = moment(cell.hierarchy.uniqueName).set(
      "year",
      moment().get("year")
    );
    const entry = current.payRoll.find((payroll) =>
      moment(payroll.date).isSame(date, "date")
    );
    if (!entry) {
      return;
    }
    const hours = [...entry.hours];
    if (entry.sumHours.FLATRATE.RESTDAY) {
      hours.push({
        createdBy: "System",
        customerName: "-",
        flatRateRestDay: entry.sumHours.FLATRATE.RESTDAY,
        type: "FLAT RATE REST DAY",
        workOrder: "-",
      });
    }
    if (entry.sumHours.FLATRATE.PICKUPDRIVER) {
      hours.push({
        createdBy: "System",
        customerName: "-",
        flatRatePickUp: entry.sumHours.FLATRATE.PICKUPDRIVER,
        type: "FLAT RATE PICKUP DRIVER",
        workOrder: "-",
      });
    }
    setDetailModal({
      label: `${current.firstName} ${current.lastName}`,
      date: cell.hierarchy.uniqueName,
      isOpen: true,
      hours,
      type: "DATE_DETAIL",
    });
  };

  const flatRateCellClick = (current) => {
    const hours = [];
    current.payRoll.forEach((entry) => {
      if (entry.sumHours.FLATRATE.RESTDAY) {
        hours.push({
          date: entry.date,
          createdBy: "System",
          flatRateRestDay: entry.sumHours.FLATRATE.RESTDAY,
          type: "FLAT RATE REST DAY",
        });
      }
      if (entry.sumHours.FLATRATE.PICKUPDRIVER) {
        hours.push({
          date: entry.date,
          createdBy: "System",
          flatRatePickUp: entry.sumHours.FLATRATE.PICKUPDRIVER,
          type: "FLAT RATE PICKUP DRIVER",
        });
      }
    });
    setDetailModal({
      label: `${current.firstName} ${current.lastName}`,
      isOpen: true,
      hours,
      type: "FLAT_RATE",
    });
  };

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        const columns = ["Name", "Operation", "Comp."];
        const filteredData = reportsHelper.applyFilters(
          pivot.current.flexmonster,
          data,
          columns
        );
        //apply all filters to data and use the index
        const current = filteredData[cell.rowIndex - 1];
        if (!current) {
          return;
        }
        if (moment(cell.hierarchy.uniqueName).isValid()) {
          dateCellClick(cell, current);
        } else if (cell.hierarchy.uniqueName === "F.Rate") {
          flatRateCellClick(current);
        }
      });
    }
  }, [pivot, data, monday, customStartDate]);

  const isTypeWeekly = periodType.value === TYPE_WEEKLY;
  const currentDate = isTypeWeekly ? monday : customStartDate;

  const flexReport = report(currentDate);

  const isLastWeek = moment()
    .subtract(1, "weeks")
    .startOf("isoWeek")
    .isSame(currentDate, "date");

  const getFilename = () =>
    reportsHelper.getFileName(currentDate, true, REPORT_NAME);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-2" />
                </div>
              ) : (
                <div className="d-flex justify-content-end align-items-center">
                  {isLastWeek ? (
                    <Button
                      className="d-flex align-items-center rounded"
                      color="warning"
                      size="sm"
                      onClick={sendNotification}
                    >
                      <FontAwesomeIcon icon={faBell} className="mr-2" />
                      <span>Send Notification</span>
                    </Button>
                  ) : null}
                </div>
              )}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  autoCompleteWeek={true}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(moment(startDate));
                    setCustomEndDate(moment(endDate));
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100 weekly-report-table">
                <FlexmonsterReact.Pivot
                  key={currentDate.toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={flexReport}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {detailModal.isOpen ? (
        <WeeklyPayrollDetailReport
          hours={detailModal.hours}
          label={detailModal.label}
          date={detailModal.date}
          type={detailModal.type}
          onClose={() => setDetailModal({})}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default WeeklyPayroll;
