import React, { useState } from "react";

import {
  faCar,
  faChevronDown,
  faChevronRight,
  faClock,
  faDatabase,
  faDownload,
  faExclamationCircle,
  faPen,
  faTruckPickup,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Col, Collapse, Row } from "reactstrap";

import { utilsHelper } from "../../helpers/utilsHelper";
import CrewWorkDayWorkTimes from "../../components/admin/workOrderDetails/CrewWorkDayWorkTimes";
import CrewWorkDayTravelTimes from "../../components/admin/workOrderDetails/CrewWorkDayTravelTimes";
import moment from "moment";
import JobTimeReportModal from "../../components/admin/JobTimeReportModal";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import JobTimeFlexMonsterModal from "../../components/admin/workOrderDetails/JobTimeFlexMonsterModal";
import AddTimeModal from "../../components/admin/workOrderDetails/AddTimeModal";
import InformationModal from "../../components/InformationModal";
import CodeTimeModal from "../../components/admin/workOrderDetails/codeTime/CodeTimeModal";
import AddDriverModal from "../../components/admin/workOrderDetails/AddDriverModal";
import TooltipItem from "../../components/TooltipItem";
import CrewWorkDayTruckDrivers from "../../components/admin/workOrderDetails/CrewWorkDayTruckDrivers";
import { useAuth } from "../../providers/authProvider";

const TYPE_WORK_TIME = "TYPE_WORK_TIME";
const TYPE_TRAVEL_TIME = "TYPE_TRAVEL_TIME";
const WO_TYPE_GROUP = "group";
const TYPE_TRUCK_DRIVER = "TYPE_TRUCK_DRIVER";

const STATUS_INVOICED = 6;
const NONJOB = "NONJOB";

const WorkOrderTimes = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [showJobTimesReportModal, setShowJobTimesReportModal] = useState(false);
  const [showJobFlexReportModal, setShowJobFlexReportModal] = useState(false);
  const [addTimeModal, setAddTimeModal] = useState(false);
  const [codeTimeModal, setCodeTimeModal] = useState(false);
  const [truckDriverModal, setTruckDriverModal] = useState({});

  const IS_READ_ONLY =
    workOrderDetails?.workOrder?.statusId === STATUS_INVOICED ||
    utilsHelper.isReadOnly(authContext);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const hasData =
    workOrderDetails.dates && Object.keys(workOrderDetails.dates).length;

  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="12">
            <div className="d-flex d-flex-row justify-content-between border-bottom">
              <h4>Job Time</h4>
              <div>
                {hasData &&
                workOrderDetails.workOrder.type === WO_TYPE_GROUP &&
                !utilsHelper.isBetaProd() ? (
                  <Button
                    color="tertiary"
                    size="sm"
                    className="ml-2 rounded"
                    onClick={() => setCodeTimeModal(true)}
                  >
                    <FontAwesomeIcon icon={faUserClock} className="mr-2" />
                    <span>Code Time</span>
                  </Button>
                ) : null}
                {!IS_READ_ONLY && (
                  <>
                    {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                      <Button
                        color="tertiary"
                        size="sm"
                        className="ml-2 rounded"
                        onClick={() =>
                          setTruckDriverModal({
                            isOpen: true,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTruckPickup}
                          className="mr-2"
                        />
                        <span>Truck Driver Pickup</span>
                      </Button>
                    ) : null}
                    <Button
                      color="tertiary"
                      size="sm"
                      className="ml-2 rounded"
                      onClick={() => setAddTimeModal(TYPE_WORK_TIME)}
                    >
                      <FontAwesomeIcon icon={faClock} className="mr-2" />
                      <span>Add Time</span>
                    </Button>
                    <Button
                      color="tertiary"
                      size="sm"
                      className="ml-2 rounded"
                      onClick={() => setAddTimeModal(TYPE_TRAVEL_TIME)}
                    >
                      <FontAwesomeIcon icon={faCar} className="mr-2" />
                      <span>Add Travel</span>
                    </Button>
                  </>
                )}
                {hasData ? (
                  <>
                    <Button
                      color="tertiary"
                      size="sm"
                      className="ml-2 rounded"
                      onClick={() => setShowJobFlexReportModal(true)}
                    >
                      <FontAwesomeIcon icon={faDatabase} className="mr-2" />
                      <span>Flex Report</span>
                    </Button>
                    <Button
                      color="tertiary"
                      size="sm"
                      className="ml-2 rounded"
                      onClick={() => setShowJobTimesReportModal(true)}
                    >
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      <span>CSV</span>
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
            {!hasData ? (
              <>
                <div className="text-center text-muted mt-4 small">
                  No data has been recorded
                </div>
              </>
            ) : (
              Object.keys(workOrderDetails.dates)
                .sort(utilsHelper.sortDays)
                .filter(
                  (date) => workOrderDetails.dates[date].crewWorkDays.length
                )
                .map((date) => {
                  const crewWorkDays =
                    workOrderDetails.dates[date].crewWorkDays;
                  return (
                    <div className="mt-3" key={date}>
                      <Button
                        key={date}
                        onClick={() =>
                          setWorkOrderDetails({
                            action: ACTIONS.TOGGLE_DATE,
                            payload: { date },
                          })
                        }
                        style={{ width: "100%" }}
                      >
                        <div className="d-flex flex-row justify-content-between m-1 align-middle">
                          <span>{moment(date).format("MM/DD/YYYY")}</span>
                          <FontAwesomeIcon
                            icon={
                              workOrderDetails.activeDates.has(date)
                                ? faChevronDown
                                : faChevronRight
                            }
                            fixedWidth
                            className="my-1"
                          />
                        </div>
                      </Button>
                      <Collapse
                        isOpen={workOrderDetails.activeDates.has(date)}
                        className="p-2"
                      >
                        {crewWorkDays
                          .sort((a, b) =>
                            a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
                          )
                          .map((crewWorkDay) => {
                            const supervisorEmployee =
                              utilsHelper.getSupervisorEmployee(
                                crewWorkDay.employees
                              );
                            const supervisor = supervisorEmployee
                              ? `${supervisorEmployee?.employee.firstName} ${supervisorEmployee?.employee.lastName}`
                              : "No Supervisor Assigned";
                            const truckDrivers =
                              crewWorkDay.employeeTruckDrivers;
                            return (
                              <div
                                className="col-12 mt-2 border px-0 rounded"
                                key={crewWorkDay.id}
                              >
                                <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                                  <div className="d-flex align-items-center col-3 px-0">
                                    <small className="text-white px-1 border border-white rounded font-weight-bold">
                                      {utilsHelper.outageLabels(
                                        crewWorkDay.outage
                                      )}
                                    </small>
                                    <small className="text-white px-1 border border-white rounded font-weight-bold ml-2">
                                      {crewWorkDay.completed
                                        ? "Day Closed"
                                        : "Day Open"}
                                    </small>
                                  </div>
                                  <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                    {utilsHelper.capitalize(crewWorkDay.shift)}{" "}
                                    Crew
                                  </div>
                                  <div className="d-flex align-items-center col-3 px-0 justify-content-end">
                                    <small className="text-white text-truncate px-1 border border-white rounded font-weight-bold">
                                      {supervisor}
                                    </small>
                                    {truckDrivers?.length ? (
                                      <TooltipItem
                                        className="ml-2 cursor-pointer"
                                        id={`${crewWorkDay.id}-TD-tooltip`}
                                        title={`Truck Driver: ${truckDrivers
                                          .map((td) =>
                                            utilsHelper.getEmployeeLabelWithPreferredName(
                                              td.employee
                                            )
                                          )
                                          .join(", ")}`}
                                      >
                                        <FontAwesomeIcon
                                          size="lg"
                                          icon={faExclamationCircle}
                                          className={`ml-2 text-white`}
                                          style={{ paddingBottom: "1px" }}
                                        />
                                      </TooltipItem>
                                    ) : null}
                                  </div>
                                </div>
                                <CrewWorkDayWorkTimes
                                  crewWorkDay={crewWorkDay}
                                />
                                <CrewWorkDayTravelTimes
                                  crewWorkDay={crewWorkDay}
                                />
                                <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                                  <div className="d-flex align-items-center col-3 px-0"></div>
                                  <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                    Truck Driver Pickup
                                  </div>
                                  {!utilsHelper.isReadOnly(authContext) ? (
                                    <div className="d-flex align-items-center col-3 px-0 justify-content-end">
                                      <Button
                                        size="sm"
                                        color="none"
                                        className="ml-1 rounded text-white border-white"
                                        onClick={() =>
                                          setTruckDriverModal({
                                            isOpen: true,
                                            crewId: crewWorkDay.crewId,
                                            date: crewWorkDay.date,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faPen}
                                          className="mr-2"
                                        />
                                        <span>Edit</span>
                                      </Button>
                                    </div>
                                  ) : null}
                                </div>
                                <CrewWorkDayTruckDrivers
                                  crewWorkDay={crewWorkDay}
                                  workOrderPartType={
                                    workOrderDetails.workOrder.partType
                                  }
                                />
                              </div>
                            );
                          })}
                      </Collapse>
                    </div>
                  );
                })
            )}
          </Col>
        </Row>
      </Col>
      {showJobTimesReportModal ? (
        <JobTimeReportModal onClose={() => setShowJobTimesReportModal(false)} />
      ) : showJobFlexReportModal ? (
        <JobTimeFlexMonsterModal
          onClose={() => setShowJobFlexReportModal(false)}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : addTimeModal ? (
        <AddTimeModal
          type={addTimeModal}
          onSubmit={() => {
            setAddTimeModal(false);
            setInformationModal({
              isOpen: true,
              title: `Add Time`,
              body: "Time added successfully.",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
          onClose={() => setAddTimeModal(false)}
        />
      ) : codeTimeModal ? (
        <CodeTimeModal
          onSubmit={() => {
            setCodeTimeModal(false);
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setCodeTimeModal(false)}
        />
      ) : truckDriverModal.isOpen ? (
        <AddDriverModal
          type={TYPE_TRUCK_DRIVER}
          defaultCrew={truckDriverModal.crewId}
          defaultDate={truckDriverModal.date}
          onSubmit={() => {
            setTruckDriverModal({});
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setTruckDriverModal({})}
        />
      ) : null}
    </Row>
  );
};
export default WorkOrderTimes;
