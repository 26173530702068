import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import { utilsHelper } from "../helpers/utilsHelper";
import { employeeSafetyCertificationsApi } from "../services/employeeSafetyCertificationsService";
import InformationModal from "./InformationModal";
import Loader from "./Loader";
import DocumentPreviewModal from "./admin/DocumentPreviewModal";

const NOT_EXPIRED = "not expired";

const SAFETY_CERTIFICATIONS = [
  {
    key: "osha10",
    name: "OSHA 10",
    expirationTimeframe: "2 years",
  },
  {
    key: "osha30",
    name: "OSHA 30",
    expirationTimeframe: NOT_EXPIRED,
  },
  {
    key: "msha",
    name: "MSHA",
    expirationTimeframe: "1 year",
  },
  {
    key: "overheadCrane",
    name: "Overhead Crane",
    expirationTimeframe: "2 years",
  },
  {
    key: "rigging101",
    name: "Rigging 101",
    expirationTimeframe: "2 years",
  },
  {
    key: "forkliftLull",
    name: "Forklift",
    expirationTimeframe: "3 years",
  },
  {
    key: "aerialPlatform",
    name: "Aerial Platform",
    expirationTimeframe: "3 years",
  },
  {
    key: "confinedSpace",
    name: "Confined Space",
    expirationTimeframe: "3 years",
  },
  {
    key: "fireWatch",
    name: "Fire Watch",
    expirationTimeframe: "3 years",
  },
  {
    key: "firstAid",
    name: "First-Aid",
    expirationTimeframe: "2 years",
  },
  {
    key: "respiratoryFitTest",
    name: "Respiratory Fit Test",
    expirationTimeframe: "1 year",
  },
  {
    key: "ncccoFc",
    name: "NCCCO Fixed Cab",
    expirationTimeframe: "5 years",
  },
];

const SafetyCertificationsModal = ({ onClose, employeeId }) => {
  const [loading, setLoading] = useState(false);
  const [certifications, setCertifications] = useState([]);

  const [documentPreviewModal, setDocumentPreviewModal] = useState({
    isOpen: false,
    file: null,
    onClose: null,
  });

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  useEffect(() => {
    if (employeeId) {
      setLoading(true);

      const init = async () => {
        const response = await employeeSafetyCertificationsApi.getAll({
          employeeId,
        });

        const data = response?.[0];

        if (data) {
          createStructure(data);
        }

        setLoading(false);
      };

      init();
    }
  }, [employeeId]);

  const createStructure = (data) => {
    Object.keys(data).forEach((key) => {
      const isNullData = data[key] === null || data[key] === undefined;

      const certification = SAFETY_CERTIFICATIONS.find(
        (cert) => cert.key === key
      );

      if (!isNullData && certification) {
        setCertifications((prev) => {
          const exits = prev.find((prevCertification) => {
            return prevCertification.name === certification.name;
          });

          if (exits) {
            return prev;
          }

          const expirationDate = new Date(data[key]);

          return [
            ...prev,
            {
              name: certification.name,
              expirationDate: expirationDate.toLocaleDateString(),
              expirationTimeframe: certification.expirationTimeframe,
              urlFile: data[`${key}_file`],
            },
          ];
        });
      }
    });
  };

  const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const downloadFile = (url) => {
    utilsHelper.downloadFile(url, getFileName(url));
  };

  const getExpirationDate = (certification) => {
    const expirationDate = new Date(certification.expirationDate);
    const expirationYears = {
      "1 year": 1,
      "2 years": 2,
      "3 years": 3,
      "5 years": 5,
    };

    if (expirationYears[certification.expirationTimeframe]) {
      expirationDate.setFullYear(
        expirationDate.getFullYear() +
          expirationYears[certification.expirationTimeframe]
      );
    }

    return expirationDate;
  };

  const isExpired = (certification) => {
    if (certification.expirationTimeframe === NOT_EXPIRED) {
      return false;
    }

    const today = new Date();
    const expirationDate = getExpirationDate(certification);

    return today > expirationDate;
  };

  const isToExpire = (certification) => {
    if (certification.expirationTimeframe === NOT_EXPIRED) {
      return false;
    }

    const today = new Date();
    const expirationDate = getExpirationDate(certification);

    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 60;
  };

  const openDocumentPreview = (certification) => {
    const isPDF = certification.urlFile.endsWith(".pdf");

    if (isPDF) {
      setDocumentPreviewModal({
        isOpen: true,
        file: certification.urlFile,
        onClose: () => setDocumentPreviewModal({ isOpen: false }),
      });
    } else {
      setInformationModal({
        isOpen: true,
        title: "Document Preview",
        rawBody: true,
        body: (
          <div className="d-flex flex-column text-center">
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              {({ zoomIn, zoomOut }) => (
                <React.Fragment>
                  <div
                    style={{ position: "absolute", zIndex: 100 }}
                    className="tools mt-2"
                  >
                    <Button
                      className="rounded mx-2 btn-light border"
                      onClick={() => zoomOut()}
                    >
                      -
                    </Button>
                    <Button
                      className="rounded btn-light border"
                      onClick={() => zoomIn()}
                    >
                      +
                    </Button>
                  </div>
                  <TransformComponent>
                    <img
                      className="img-fluid rounded"
                      src={certification.urlFile}
                      alt="Document"
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        ),
        onClose: () => setInformationModal({ isOpen: false }),
      });
    }
  };

  const hasCertifications = certifications.length > 0;

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={informationModal.onClose}
    />
  ) : documentPreviewModal.isOpen ? (
    <DocumentPreviewModal {...documentPreviewModal} />
  ) : (
    <Modal isOpen={true} toggle={onClose} size="md">
      <ModalHeader>Safety Certifications</ModalHeader>

      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : hasCertifications ? (
          <Table className="border col-12 px-0 mb-0 small">
            <tbody>
              {certifications.map((certification) => (
                <tr>
                  <td className="font-weight-bold">{certification.name}</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Badge
                        color={
                          isExpired(certification)
                            ? "danger"
                            : isToExpire(certification)
                            ? "warning"
                            : "eight"
                        }
                      >
                        {certification.expirationDate}
                      </Badge>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="rounded mr-2"
                        color="seven"
                        size="sm"
                        onClick={() => openDocumentPreview(certification)}
                      >
                        View
                      </Button>

                      <Button
                        className="rounded"
                        color="link"
                        size="sm"
                        onClick={() => downloadFile(certification.urlFile)}
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faFileDownload}
                          className="text-seven"
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center text-muted mt-4 mb-4 small">
            No data has been recorded
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SafetyCertificationsModal;
