import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";

import { useCrewManagement } from "../../../providers/crewManagementProvider";
import { employeesApi } from "../../../services/employeeServices";
import { rolesApi } from "../../../services/roleServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import Loader from "../../../components/Loader";
import InformationModal from "../../InformationModal";
import SelectWrapper from "../SelectWrapper";
import { useAuth } from "../../../providers/authProvider";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { crewManagementHelper } from "../../../helpers/crewManagementHelper";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";
import { useNavigate } from "react-router-dom";

const ROLES_MAX_PAGE_SIZE = 999;
const MAX_PAGE_SIZE = 30;
const NONJOB = "NONJOB";

const EMPLOYEE_DEFAULT_TYPE = 1;
const EMPLOYEE_CONTRACTOR_TYPE = 2;

const AddOverageEmployeeModal = ({ onClose, onSubmit }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [authContext] = useAuth();

  const [crewManagement] = useCrewManagement();

  const [loading, setLoading] = useState();

  const [selectedEmployeeType, setSelectedEmployeeType] = useState(
    EMPLOYEE_DEFAULT_TYPE
  );
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedRole, setSelectedRole] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [workOrderStatusContext] = useWorkOrderStatus();
  const navigate = useNavigate();

  const handleSuccessModal = () => {
    setInformationModal({
      isOpen: true,
      title: `Add ${
        workOrderDetails.workOrder.workOrderType === NONJOB ? "" : "Overage"
      } Employee`,
      body: `${
        workOrderDetails.workOrder.workOrderType === NONJOB
          ? "Employee"
          : "Overage employee"
      } added successfully.`,
      onClose: () => onSubmit(selectedRole, selectedEmployee),
    });
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    if (!selectedRole || !selectedEmployee) {
      return setInformationModal({
        isOpen: true,
        title: `Add ${
          workOrderDetails.workOrder.workOrderType === NONJOB ? "" : "Overage"
        } Employee`,
        body: `Please select ${
          workOrderDetails.workOrder.workOrderType === NONJOB ? "" : "role and "
        } employee.`,
      });
    }
    setLoading(true);
    let conflictModal = null;
    if (workOrderDetails.workOrder.workOrderType === NONJOB) {
      const conflicts = crewManagementHelper.employeeConflicts(
        workOrderStatusContext,
        workOrderDetails.workOrder,
        selectedEmployee,
        navigate
      );

      if (conflicts) {
        conflictModal = {
          isOpen: true,
          title: "Scheduling Conflict",
          rawBody: true,
          body: conflicts,
          size: "lg",
          onClose: () => {
            setInformationModal({ isOpen: false });
            setTimeout(() => {
              handleSuccessModal();
            });
          },
        };
      }
    }

    if (!conflictModal) {
      handleSuccessModal();
    } else {
      setInformationModal(conflictModal);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal?.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
      rawBody={informationModal.rawBody}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Employee to Crew</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row>
                <Label
                  sm={4}
                  className="d-flex align-items-center justify-content-start"
                >
                  Employee Type
                </Label>
                <Col sm={8}>
                  <div className="d-flex flex-grow-1 justify-content-start">
                    <div className="col-6 pl-0">
                      <Button
                        onClick={() =>
                          setSelectedEmployeeType(EMPLOYEE_DEFAULT_TYPE)
                        }
                        color={
                          selectedEmployeeType === EMPLOYEE_DEFAULT_TYPE
                            ? "primary"
                            : "white"
                        }
                        className={`col-12 outline-none ${
                          selectedEmployeeType === EMPLOYEE_DEFAULT_TYPE
                            ? "border-white bg-primary text-white"
                            : "border-primary bg-white text-primary"
                        }`}
                      >
                        Regular
                      </Button>
                    </div>
                    <div className="col-6 pr-0">
                      <Button
                        onClick={() =>
                          setSelectedEmployeeType(EMPLOYEE_CONTRACTOR_TYPE)
                        }
                        color={
                          selectedEmployeeType === EMPLOYEE_CONTRACTOR_TYPE
                            ? "primary"
                            : "white"
                        }
                        className={`col-12 outline-none ${
                          selectedEmployeeType === EMPLOYEE_CONTRACTOR_TYPE
                            ? "border-white bg-primary text-white"
                            : "border-primary bg-white text-primary"
                        }`}
                      >
                        Contractor
                      </Button>
                    </div>
                  </div>
                </Col>
              </FormGroup>
              {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                <FormGroup row className="mb-0">
                  <Label
                    sm={4}
                    className="d-flex flex-column align-items-start justify-content-start"
                  >
                    <span>Role</span>
                    <small className="text-warning">
                      Only roles without a slot are listed here
                    </small>
                  </Label>
                  <Col sm={8}>
                    <SelectWrapper
                      key={`role-${crewManagement.crossCompany}`}
                      entity="role"
                      filterItemFunction={(role) =>
                        !crewManagement.workOrderCrews?.find(
                          (woc) => parseInt(woc.roleId) === parseInt(role.id)
                        )
                      }
                      formatItemFunction={utilsHelper.getRoleValue}
                      fetchFunction={rolesApi.getEmployeeRoles}
                      fetchParameters={{
                        crossCompany: crewManagement.crossCompany,
                        isEnabledForCrew: true,
                        pageSize: ROLES_MAX_PAGE_SIZE,
                        jobSourceId: authContext.currentUser.jobSourceId,
                      }}
                      defaultSelected={selectedRole}
                      onSelected={(role) => setSelectedRole(role)}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row className="mb-0">
                <Label
                  sm={4}
                  className="d-flex align-items-center justify-content-start"
                >
                  Employee
                </Label>
                <Col sm={8}>
                  <SelectWrapper
                    key={`employee-${crewManagement.crossCompany}-${selectedEmployeeType}`}
                    entity="employee"
                    filterItemFunction={(item) =>
                      !crewManagement.workOrderCrews?.find((woc) =>
                        woc.employees.find((e) => e.id === item.id)
                      )
                    }
                    formatItemFunction={
                      utilsHelper.getEmployeeOptionWithPreferredName
                    }
                    fetchFunction={employeesApi.getEmployees}
                    fetchParameters={{
                      type: selectedEmployeeType,
                      pageSize: MAX_PAGE_SIZE,
                      isNonJob:
                        workOrderDetails.workOrder.workOrderType === NONJOB
                          ? "true"
                          : "false",
                      includeAssignations: true,
                      crossCompany: crewManagement.crossCompany,
                      isActive: true,
                      jobSourceId: authContext.currentUser.jobSourceId,
                    }}
                    defaultSelected={selectedEmployee}
                    onSelected={(employee) => {
                      if (!employee) {
                        setSelectedEmployee();
                      } else {
                        setSelectedEmployee({
                          ...employee,
                          isOverage:
                            workOrderDetails.workOrder.workOrderType !== NONJOB,
                        });
                        if (
                          workOrderDetails.workOrder.workOrderType === NONJOB
                        ) {
                          setSelectedRole(employee.role);
                        }
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddOverageEmployeeModal;
