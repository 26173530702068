import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  ListGroup,
  ListGroupItem,
  Input,
  CustomInput,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { perDiemAdjustmentsApi } from "../../services/perDiemAdjustmentServices";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import {
  faExclamationCircle,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipItem from "../TooltipItem";
import moment from "moment";
import { utilsHelper } from "../../helpers/utilsHelper";

const AdjustGlobalPerDiemModal = ({ onClose, onSubmit }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crew, setCrew] = useState();
  const [employeeEnabled, setEmployeeEnabled] = useState({});
  const [adjustmentToAdd, setAdjustmentToAdd] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    if (workOrderDetails.workOrder.perDiemAdjustments.length) {
      const crews = workOrderDetails.workOrder.crewWorkOrders.map(
        (cwo) => cwo.crew
      );
      const crew = crews.find(
        (crew) =>
          workOrderDetails.workOrder.perDiemAdjustments.filter(
            (perDiemAdjustment) =>
              crew.employeeCrews
                .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                .find((ec) => ec.employee.id === perDiemAdjustment.employeeId)
          ).length === workOrderDetails.workOrder.perDiemAdjustments.length
      );
      setCrew(crew);
    }
  }, [workOrderDetails.workOrder]);

  useEffect(() => {
    if (
      workOrderDetails.workOrder.perDiemAdjustments.length &&
      crew &&
      !Object.keys(employeeEnabled).length
    ) {
      const employeeEnabled =
        workOrderDetails.workOrder.perDiemAdjustments.reduce((p, c) => {
          p[c.employeeId] = true;
          return p;
        }, {});
      setEmployeeEnabled(employeeEnabled);
    }
  }, [workOrderDetails.workOrder, crew, employeeEnabled]);

  useEffect(() => {
    if (
      workOrderDetails.workOrder.perDiemAdjustments.length &&
      crew &&
      !Object.keys(adjustmentToAdd).length
    ) {
      const adjustmentToAdd =
        workOrderDetails.workOrder.perDiemAdjustments.reduce((p, c) => {
          p[c.employeeId] = { id: c.id, amount: c.amount, reason: c.reason };
          return p;
        }, {});
      setAdjustmentToAdd(adjustmentToAdd);
    }
  }, [workOrderDetails.workOrder, crew, adjustmentToAdd]);

  const onAmountChange = (amount, employeeId) => {
    adjustmentToAdd[employeeId] = {
      ...adjustmentToAdd[employeeId],
      amount,
    };
    setAdjustmentToAdd({ ...adjustmentToAdd });
  };

  const onReasonChange = (reason, employeeId) => {
    adjustmentToAdd[employeeId] = {
      ...(adjustmentToAdd[employeeId] || {}),
      reason,
    };
    setAdjustmentToAdd({ ...adjustmentToAdd });
  };

  const validateNotZero = (target) => {
    if (parseFloat(target.value) === 0) {
      target.setCustomValidity("Enter a amount other than zero.");
    } else {
      target.setCustomValidity("");
    }
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    const items = Object.keys(adjustmentToAdd).map((employeeId) => ({
      employeeId,
      deleted: !employeeEnabled[employeeId],
      id: adjustmentToAdd[employeeId].id,
      reason: adjustmentToAdd[employeeId].reason,
      workOrderId: workOrderDetails.workOrder.id,
      isGlobal: true,
      amount: adjustmentToAdd[employeeId].amount || 0,
      createdAt: moment().toISOString(),
      updatedAt: moment().toISOString(),
    }));
    if (!items.length) {
      return setInformationModal({
        isOpen: true,
        title: `Add Per Diem Increase`,
        body: "Add increase to at least one employee.",
      });
    }
    setLoading(true);
    try {
      const response = await perDiemAdjustmentsApi.editBulk({
        items,
      });

      setLoading(false);

      const dates = response
        .map((limit) => moment(limit.date).format("MM/DD/YYYY"))
        .join(", ");

      const bodyModal =
        response.length === 0
          ? "Increase added successfully."
          : `The per diem increase was successfully applied, except for those days where the per diem increase is $55: ${dates}`;

      setInformationModal({
        isOpen: true,
        title: `Add Per Diem Increase`,
        body: bodyModal,
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Add Per Diem Increase`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Per Diem Increase</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <ListGroup>
            <ListGroupItem row className="d-flex align-items-center bg-lighter">
              <Label sm={4} className="text-sm-left">
                Crew
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  id="crewSelect"
                  type="select"
                  name="crewSelect"
                  value={crew?.id || ""}
                  onChange={(event) => {
                    const crews = workOrderDetails.workOrder.crewWorkOrders.map(
                      (cwo) => cwo.crew
                    );
                    const crew = crews.find(
                      (c) => c.id === event.currentTarget.value
                    );
                    setCrew(crew);
                  }}
                >
                  <option value={""}>Select a Crew</option>
                  {workOrderDetails.workOrder.crewWorkOrders
                    .filter((crewWorkOrder) =>
                      crewWorkOrder.crew.employeeCrews
                        .filter((ec) =>
                          utilsHelper.isEmployeeCrewActiveOnDate(ec)
                        )
                        .find((ec) => ec.isCrewLead)
                    )
                    .map((crewWorkOrder) => {
                      const sup = crewWorkOrder.crew.employeeCrews
                        .filter((ec) =>
                          utilsHelper.isEmployeeCrewActiveOnDate(ec)
                        )
                        .find((ec) => ec.isCrewLead);
                      return (
                        <option
                          key={crewWorkOrder.crew.id}
                          value={crewWorkOrder.crew.id}
                        >
                          {`${utilsHelper.getEmployeeLabelWithPreferredName(
                            sup.employee
                          )} (${crewWorkOrder.shift}${
                            crewWorkOrder.outage
                              ? `/${crewWorkOrder.outage}`
                              : ""
                          })`}
                        </option>
                      );
                    })}
                </CustomInput>
              </Col>
            </ListGroupItem>
            {crew ? (
              <ListGroup className="mt-3">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                  tag="div"
                >
                  <span>Crew Member</span>
                  <span></span>
                </ListGroupItem>
                {crew.employeeCrews
                  .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                  .map((employeeCrew) => (
                    <ListGroupItem
                      key={employeeCrew.id}
                      className="d-flex justify-content-between align-items-center py-2"
                      tag="div"
                    >
                      <span>
                        {utilsHelper.getEmployeeLabelWithPreferredName(
                          employeeCrew.employee
                        )}
                      </span>
                      <div className="col-8 text-right px-0">
                        {employeeEnabled[employeeCrew.employee.id] ? (
                          <div className="flex-grow-1 d-flex align-items-center">
                            <ListGroup className="col-12 px-0">
                              <ListGroupItem
                                className="d-flex flex-column py-3 border-bottom"
                                tag="div"
                              >
                                <FormGroup row>
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      setEmployeeEnabled({
                                        ...employeeEnabled,
                                        [employeeCrew.employee.id]: false,
                                      })
                                    }
                                    icon={faTimesCircle}
                                    className="mt-2 cursor-pointer text-danger bg-white upper-close"
                                  />
                                  <Col className="d-flex justify-content-end align-items-center w-100">
                                    <Input
                                      type="number"
                                      placeholder="Enter the amount.."
                                      max={99999}
                                      required={true}
                                      value={
                                        adjustmentToAdd[
                                          employeeCrew.employee.id
                                        ]?.amount || ""
                                      }
                                      onChange={(event) => {
                                        validateNotZero(event.target);
                                        onAmountChange(
                                          event.currentTarget.value,
                                          employeeCrew.employee.id
                                        );
                                      }}
                                    />
                                    <TooltipItem
                                      className="ml-2"
                                      id={`${employeeCrew.employee.id}-tooltip`}
                                      title="Enter only the $ amount of the increase."
                                    >
                                      <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                        className="ml-2 text-warning"
                                        style={{ paddingBottom: "1px" }}
                                      />
                                    </TooltipItem>
                                  </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                  <Col className="d-flex justify-content-end align-items-center w-100">
                                    <Input
                                      type="text"
                                      placeholder="Enter the reason.."
                                      required={true}
                                      maxLength="100"
                                      value={
                                        adjustmentToAdd[
                                          employeeCrew.employee.id
                                        ]?.reason || ""
                                      }
                                      onChange={(event) =>
                                        onReasonChange(
                                          event.currentTarget.value,
                                          employeeCrew.employee.id
                                        )
                                      }
                                    />
                                  </Col>
                                </FormGroup>
                              </ListGroupItem>
                            </ListGroup>
                          </div>
                        ) : (
                          <Button
                            className="rounded"
                            size="sm"
                            color="primary"
                            onClick={() =>
                              setEmployeeEnabled({
                                ...employeeEnabled,
                                [employeeCrew.employee.id]: true,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              className="mr-2 text-white"
                            />
                            Add Increase
                          </Button>
                        )}
                      </div>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            ) : null}
          </ListGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"warning"} type="submit">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AdjustGlobalPerDiemModal;
