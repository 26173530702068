import moment from "moment";
import React from "react";

import { Col, Row } from "reactstrap";

import Badge from "reactstrap/lib/Badge";

import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";

const STATUS_OPEN = 1;

const isStatusActive = (workOrder, statusId) => workOrder.statusId === statusId;

const WorkOrderStatus = () => {
  const [workOrderStatusContext] = useWorkOrderStatus();
  const [workOrderDetails] = useWorkOrderDetails();

  const statusLog = (workOrder, statusId, index) => {
    const statusLogs = workOrder.workOrderStatusLog
      ?.filter((log) => log.statusId === statusId)
      .sort((x, y) =>
        moment(x.modifiedAt || x.createdAt).isSameOrAfter(
          moment(y.modifiedAt || y.createdAt)
        )
          ? 1
          : -1
      );
    const statusLog = statusLogs.pop();
    let time = statusLog?.modifiedAt || statusLog?.createdAt;
    if (!statusLog && statusId === STATUS_OPEN) {
      time = time || workOrder.createdDate;
    } else if (!statusLog) {
      return null;
    }
    const author = statusLog?.modifiedByUser || statusLog?.createdByUser;
    return (
      <div
        className={`small d-flex flex-column align-items-${
          index === 0
            ? "start"
            : index === workOrderStatusContext.workOrderStatus.length - 1
            ? "end"
            : "center"
        } mt-2 ${!isStatusActive(workOrder, statusId) ? "text-muted" : ""}`}
      >
        <small>{time ? moment(time).format("MM-DD-YYYY HH:mm") : "-"}</small>
        {statusLog ? (
          <small>
            By {author ? `${author?.firstName} ${author?.lastName}` : "N/A"}
          </small>
        ) : null}
      </div>
    );
  };

  return (
    <Col
      className="d-flex flex-column align-items-center col-12 pt-3 border-top"
      style={{ minWidth: 1200 }}
    >
      <h4 className="mb-4">Work Order Status</h4>
      <div className="min-width-900 col-12">
        <div className="d-flex justify-content-between align-items-center col-12">
          <div className="progress-line w-100 mx-0 border-top"></div>
        </div>
        <Row className="justify-content-between mx-0 position-relative">
          <Row className="justify-content-between status-container mx-0">
            {workOrderStatusContext.workOrderStatus.map((status, i) => (
              <div
                key={status.id}
                className={`d-flex flex-column align-items-${
                  i === 0
                    ? "start"
                    : i === workOrderStatusContext.workOrderStatus.length - 1
                    ? "end"
                    : "center"
                }`}
              >
                <Badge
                  color={
                    isStatusActive(workOrderDetails.workOrder, status.id)
                      ? "success"
                      : "lighter"
                  }
                >
                  {status.status}
                </Badge>
                {statusLog(workOrderDetails.workOrder, status.id, i)}
              </div>
            ))}
          </Row>
        </Row>
      </div>
    </Col>
  );
};

export default WorkOrderStatus;
